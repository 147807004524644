import React from 'react';

import { ThemeProvider } from '@emotion/react';
import { PAYPAL_THEME } from '@paypalcorp/pp-react-theme';

import * as ComponentsMap from '@paypalcorp/brc-components';

import PropTypes from 'prop-types';

import { NavFragment } from '@paypalcorp/globalnavsdk/NavFragment';

export const renderComponent = (
  { componentData, pageInfo, index },
  processComponentProps,
) => {
  let { componentType, ...componentProps } = componentData;
  const Component = ComponentsMap[componentType];
  const wasComponentFound = !!Component;

  if (!wasComponentFound) {
    return <div data-missed={componentType} />;
  }

  if (typeof processComponentProps === 'function') {
    componentProps = processComponentProps({
      componentProps,
      componentType,
      pageInfo,
    });
  }

  return <Component {...componentProps} key={`${componentType}-${index}`} />;
};

export const renderCmsComponents = (
  components,
  pageInfo,
  processComponentProps,
) =>
  components.map((componentData, index) =>
    renderComponent({ componentData, pageInfo, index }, processComponentProps),
  );

const { SubNav, ParentProvider, ArticleStickyButton, Disclosure } =
  ComponentsMap;

const renderSubNav = ({ subNav }) => {
  if (!subNav) {
    return <div data-missed="SubNav" />;
  }

  // Checking if the SubNav has at least one Dropdown item
  const hasDropdownItems = subNav?.itemCollection?.some(
    (item) => item?.type === 'Dropdown',
  );

  return (
    <SubNav className={!hasDropdownItems && 'no-dropdown-items'} {...subNav} />
  );
};

const PARDOT_SAVE_FORM_ENDPOINT = 'brc/api/pardot/save-form-data';

export const getParentProviderProps = ({
  fpti,
  pageInfo = {},
  isBrowserEnv = false,
}) => ({
  cmsEngineContext: {
    apis: {
      pardot: {
        saveFormData: `/${pageInfo?.locality?.country?.toLowerCase() || 'us'}/${PARDOT_SAVE_FORM_ENDPOINT}`,
      },
    },
    app: {
      appEntryPoint: pageInfo.resourceHub === 'BRC' ? 'brc/' : 'money-hub/',
    },
    environment: {
      // Don't add "host" property as that's used to format our relative URLs from
      // pp-com-components side. And this is something we don't want since we already
      // format our URLs using the "linkResolver" fn.
      isCMSPreview: pageInfo.isCMSPreview,
      runMode: pageInfo.runMode,
      baseURI: pageInfo.baseURI,
      baseNolocaleUri: pageInfo.baseNoLocaleUri,
      pageURI: pageInfo.pageURI,
      pageSegment: pageInfo.pageSegment,
      queryStringParams: pageInfo.queryStringParams,
      theme: pageInfo.theme ?? 'regular',
      reduceMotion: pageInfo.reduceMotion,
    },
    requestor: {
      userHome: 'NONE',
      tracking: {
        fpti: isBrowserEnv ? fpti : {},
      },
      encryptedAccountNum: '',
      csrfToken: pageInfo.csrf,
      loggedIn: pageInfo.isLoggedIn,
      locality: pageInfo.locality,
    },
  },
  componentKitContext: {},
  additionalContext: {},
});

const PageTemplate = ({
  articleStickyButton,
  children,
  className,
  fpti = {},
  globalDisclosure,
  pageInfo,
  subNav,
}) => {
  const isBrowserEnv = typeof window !== 'undefined';

  const parentProviderProps = getParentProviderProps({
    fpti,
    pageInfo,
    isBrowserEnv,
  });

  return (
    <ThemeProvider theme={PAYPAL_THEME}>
      <ParentProvider {...parentProviderProps}>
        <NavFragment type="header" />
        <div
          id="document-body-root"
          className={className}
          data-served-by={pageInfo.contentServedFrom}
          data-brc-experience="rebrand-experience"
        >
          {renderSubNav({ subNav })}
          {children}
          {articleStickyButton && (
            <ArticleStickyButton {...articleStickyButton} />
          )}
        </div>
        <NavFragment type="footer">
          {globalDisclosure && (
            <NavFragment.After>
              <section className="footer-disclosure grid">
                <div className="disclosure-container">
                  <Disclosure {...globalDisclosure} />
                </div>
              </section>
            </NavFragment.After>
          )}
        </NavFragment>
      </ParentProvider>
    </ThemeProvider>
  );
};

export const pageCommonPropTypes = {
  content: PropTypes.shape({
    pageData: PropTypes.shape({
      articleStickyButton: PropTypes.shape({
        ariaLabel: PropTypes.string.isRequired,
      }),
      components: PropTypes.arrayOf(
        PropTypes.shape({
          componentType: PropTypes.string.isRequired,
        }),
      ).isRequired,
      subNav: PropTypes.object.isRequired,
      globalDisclosure: PropTypes.object.isRequired,
    }).isRequired,
  }).isRequired,
  pageInfo: PropTypes.object.isRequired,
  fpti: PropTypes.object,
};

PageTemplate.propTypes = {
  articleStickyButton: PropTypes.shape({
    ariaLabel: PropTypes.string.isRequired,
  }),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  fpti: PropTypes.object,
  globalDisclosure: PropTypes.object,
  pageInfo: PropTypes.object.isRequired,
  subNav: PropTypes.object.isRequired,
};

export default PageTemplate;
